import React from 'react'
import gif from '../assets/sheep.gif'
import { Link } from 'gatsby'

class Gif2Page extends React.Component {
    
    render() {
        return (
            <div class="giflayout">
                <img src={gif}></img>
                <Link to="gif3" class="button">GIVE ME THE SURPRISE ALREADY!!</Link>
            </div>
        )
    }
}

export default Gif2Page